import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import DashboardComponents from '../components/DashboardComponents';
import AuthRoute from '../components/AuthRoute';
import Loading from '../components/Loading';

const Dashboard = () => {
  const [loading, setLoading] = useState(true); 

  return (
    <div>
      <Helmet>
        <title>Reseller | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading && (
          <Loading />
        )
      }
      <div>
        <Navbar />
        <DashboardComponents setLoading={setLoading} />
      </div>
    </div>
  );
};

export default Dashboard;
