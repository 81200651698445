import React, { useState } from 'react';
import RegisterForm from '../components/RegisterForm';
import OtpForm from '../components/OtpForm';

const Register = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <div>
      {showOtp ? (
          <OtpForm setShowOtp={setShowOtp} email={email} />
        ):(
          <RegisterForm setShowOtp={setShowOtp} email={email} setEmail={setEmail} />
        )
      }
    </div>
  );
};

export default Register;
