import React, { useEffect, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';

const PromoCodeList = ({ email }) => {
  const [promoCodes, setPromoCodes] = useState({
    active_promo_codes: [],
    claimed_promo_codes: [],
    expired_promo_codes: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const response = await axios.post('https://api.jemputlah.com/api/promo-codes', { email });
        setPromoCodes(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load promo codes');
        setLoading(false);
      }
    };

    fetchPromoCodes();
  }, [email]);

  if (loading) return <div>Loading promo codes...</div>;
  if (error) return <div>{error}</div>;

  const renderPromoCodes = (codes, title) => (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      {codes.length > 0 ? (
        <div>
          <ul className="space-y-4">
            {codes.map((code, index) => (
              <li key={index} className='space-y-2'>
                {title === "Active Promo Codes" && (
                  <div>
                    <div className='flex items-stretch justify-center'>
                      <input type='text' className='border rounded-lg py-2 px-3 w-full uppercase' value={code.code}></input>
                      <button onClick={() => copyToClipboard(code.code)} className=' rounded-lg py-2 px-3 bg-green-400 hover:bg-green-500 text-white'><FaCopy /></button>
                    </div>
                    <div className='bg-white p-4 rounded-lg shadow-md'>
                      <p>Diskaun: RM{code.discount}</p>
                      <p>Sah sehingga: {new Date(new Date(code.expirationDate).setDate(new Date(code.expirationDate).getDate() - 1)).toLocaleDateString('en-GB')}</p>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          
          <div className="w-full"> {/* Allows horizontal scrolling on small screens */}
            <ul className="flex flex-wrap space-x-2"> {/* 'flex' for horizontal layout, 'space-x-4' for spacing, 'min-w-max' prevents items from shrinking */}
              {codes.map((code, index) => (
                <li key={index} className="bg-gray-100 p-1 my-1 border rounded-lg">
                  {title !== "Active Promo Codes" && (
                    <div className="text-sm">
                      {code.code}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <p>No promo codes available.</p>
      )}
    </div>
  );

  const copyToClipboard = (promoCode) => {
    navigator.clipboard.writeText(promoCode).then(() => {
      alert('Promo code copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy promo code:', error);
    });
  };

  return (
    <div className="py-6">
      <div className="space-y-8"> 
        {renderPromoCodes(promoCodes.active_promo_codes, 'Active Promo Codes')}
        {renderPromoCodes(promoCodes.claimed_promo_codes, 'Claimed Promo Codes')}
        {renderPromoCodes(promoCodes.expired_promo_codes, 'Expired Promo Codes')}
      </div>
    </div>
  );
};

export default PromoCodeList;
