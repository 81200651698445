import React, { useState } from 'react';
import LoginForm from '../components/LoginForm';
import OtpForm from '../components/OtpForm';

const Login = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <div>
      {showOtp ? (
          <OtpForm setShowOtp={setShowOtp} email={email} />
        ):(
          <LoginForm setShowOtp={setShowOtp} email={email} setEmail={setEmail} />
        )
      }
    </div>
  );
};

export default Login;
