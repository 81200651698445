import React from 'react';
import { FaMoneyBillWave, FaCode } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import pembayaran from '../img/pembayaran.jpg';
import statistik from '../img/statistik.jpg';

const LandingPage = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle the redirect to /register
  const handleRegisterRedirect = () => {
    // navigate('/register');
    window.open('/register');
  };

  const handleLoginRedirect = () => {
    // navigate('/login');
    window.open('/login');
  };

  const handleJemputlah = () => {
    window.open('https://jemputlah.com', '_blank');
  };

  return (
    <div className="bg-gray-50 min-h-screen pb-8">
      <Navbar />
      {/* Hero Section */}
      <header className="bg-red-400 text-white h-screen flex items-center justify-center">
        <div className="text-center w-10/12 lg:w-2/3">
          <h1 className="text-5xl font-extrabold tracking-tight leading-tight">
            Buat Duit Sebagai Reseller Kad Kahwin Digital RSVP
          </h1>
          <p className="text-lg mt-4 opacity-90">
            Daftar percuma dan tak perlu pandai design
          </p>
          <button onClick={handleRegisterRedirect} className="mt-8 bg-white text-red-400 font-semibold px-8 py-3 rounded-full shadow-lg hover:bg-gray-100 transition">
            Daftar Sekarang
          </button>
          <button onClick={handleLoginRedirect} className="mt-8 font-semibold px-8 py-3 transition">
            Log Masuk
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto mt-16 px-4 w-11/12 lg:w-3/4">
        {/* Step 1 */}
        <section className="mb-16 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            <FaCode className="inline-block text-red-400 mr-2" /> Step 1: Daftar Percuma
          </h2>
          <p className="text-gray-600 text-lg leading-relaxed mb-6">
            <span className='font-bold'>Daftar percuma</span> sebagai reseller <span className='font-bold'>Kad Kahwin Digital Jemputlah</span>. Dekat page reseller nanti, anda boleh generate <span className='font-bold'>Promo Code</span> yang boleh digunakan untuk mendapatkan akses Kad Kahwin Digital pada harga reseller iaitu <span className='font-bold'>RM10</span> (harga biasa RM50).
          </p>
          <button onClick={handleRegisterRedirect} className="bg-red-400 text-white font-semibold px-8 py-3 rounded-full shadow-lg hover:bg-red-500 transition">
            Daftar Sekarang
          </button>
        </section>

        {/* Step 2 */}
        <section className="mb-16 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            <FaMoneyBillWave className="inline-block text-red-400 mr-2" /> Step 2: Buat Duit
          </h2>
          <p className="text-gray-600 text-lg leading-relaxed mb-6">
            Ada pelbagai <span className='font-bold'>cara buat duit</span> sebagai reseller Kad Kahwin Digital RSVP.
          </p>
          <div className="text-left bg-white shadow-md rounded-lg p-6 mb-6">
            <ol className="list-decimal ml-6 space-y-4 text-gray-700 text-lg">
              <li>
                Buka <span className='font-bold'>servis buat Kad Kahwin Digital RSVP</span>. Letak harga <span >RM50</span>. Customer bayar <span className='font-bold'>RM50</span>, anda bayar <span className='font-bold'>RM10</span> untuk akses. Untung <span className='font-bold'>RM40</span> dalam tangan.
              </li>
              <li>
                <span className='font-bold'>Jual Promo Code</span>. Generate Promo Code dekat page reseller dan jual pada harga <span className='font-bold'>RM20</span>. Untung <span className='font-bold'>RM20</span>, customer dapat diskaun.
              </li>
              <li>
                Jika anda buat bisnes wedding (MUA, Fotografer, dll.), guna Promo Code sebagai <span className='font-bold'>leverage</span> untuk promosi bisnes anda.
              </li>
            </ol>
          </div>
        </section>

        {/* Disclaimer */}
        <section className="bg-gray-100 p-8 rounded-lg shadow-lg mb-16">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Nota:</h3>
          <p className="text-gray-600 leading-relaxed mb-6">
            Kalau anda nak <span className='font-bold'>buat servis</span> Kad Kahwin Digital, pastikan anda mahir gunakan web app <span className='font-bold'>Jemputlah.com</span>. Cubalah buat 1 kad. <span className='font-bold'>10 minit</span> dah boleh mahir.
          </p>
          <button onClick={handleJemputlah} className="bg-red-400 text-white font-semibold px-8 py-3 rounded-full shadow-lg hover:bg-red-500 transition">
            Klik Sini
          </button>
        </section>

        {/* Bukti */}
        <section className="mb-16 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            Ada ke orang nak bayar RM50 untuk Kad Kahwin Digital RSVP?
          </h2>
          <p className="text-gray-600 text-lg leading-relaxed mb-6">
            Ada. Ni buktinya. <span className='font-bold'>RM50</span> tu memang <span className='font-bold'>harga pasaran</span> untuk <span className='font-bold'>Kad Kahwin Digital siap RSVP</span>. Anda boleh buat research sendiri.
          </p>
          <div className="text-left bg-white shadow-md rounded-lg p-6 mb-6">
            <img src={pembayaran} alt="Pembayaran" className='w-full md:max-w-sm mx-auto' />
          </div>
        </section>

        {/* Potensi */}
        <section className="mb-16 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            Potensi
          </h2>
          <p className="text-gray-600 text-lg leading-relaxed mb-6">
            Secara purata, ada <span className='font-bold'>152,832</span> pasangan muslim berkahwin <span className='font-bold'>setiap tahun</span>. Andai kata <span className='font-bold'>60%</span> ialah golongan yang mampu (M40 + T20), maka ada <span className='font-bold'>91,699</span> berpotensi untuk menjadi pengguna Kad Kahwin Digital ini.
          </p>
            <img src={statistik} alt="Statistik" className='w-full ' />
        </section>

        <section className='mb-16 text-center'>
          <p className="text-gray-600 mt-4 text-2xl">
            Apa-apa pun, anda cubalah dahulu. Mana tahu ada jalan dibukakan rezeki untuk anda disini.
          </p>
        </section>

        {/* Call to Action */}
        <section className="text-center mb-16">
          <p className="text-gray-700 text-lg mb-6">Cubalah. <span className='font-bold'>Daftar percuma</span> je.</p>
          <button onClick={handleRegisterRedirect} className="bg-red-400 text-white font-semibold px-8 py-3 rounded-full shadow-lg hover:bg-red-500 transition">
            Daftar Sekarang
          </button>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
