import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from './Loading';
import Auth from './Auth';  // Import Auth component

const AuthRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(null);  // Default is null (no decision yet)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (login === false && location.pathname !== '/register') {
      setLoading(false);
      navigate('/login');  // Redirect to login if not authenticated
    } else if (login === true) {
      setLoading(false);
      navigate('/dashboard');
    } else if (location.pathname === '/register') {
      setLoading(false);
    }
  }, [login, location.pathname, navigate]);

  if (loading) {
    // While loading, show a loading screen and the Auth component
    return (
      <div>
        <Loading />
        <Auth setLogin={setLogin} />  {/* Auth component handles login logic */}
      </div>
    );
  }

  return <Component {...rest} />;
};

export default AuthRoute;
