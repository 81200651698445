// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<AuthRoute component={Dashboard} />} />
        <Route path="/login" element={<AuthRoute component={Login} />} />
        <Route path="/register" element={<AuthRoute component={Register} />} />
      </Routes>
    </Router>
  );
}

export default App;
